import { Drawer } from '@material-ui/core';
import React from 'react';
import { CartRailStyles } from '@/clientCore/cart/components/CartRail/CartRail.styles';
import CartHeader from '@/clientCore/cart/components/CartHeader';
import { useCartRail } from '@/clientCore/cart/components/CartRail/hooks/useCartRail';
import CartRailBody from '@/clientCore/cart/components/CartRail/components/RailBody';

const CartRail = (): JSX.Element => {
  const railClasses = CartRailStyles();
  const [data, handlers] = useCartRail();
  const {
    cart,
    isCartRailOpen,
    isEmptyCart,
    isLocalized,
    showInfoAndWarningView,
    alertMessageText,
    showEditCart,
    show7DLInlineWarning,
    show7DLCheckoutWarning,
    cartChangedAlert
  } = data;

  const { handleRailClose, setShow7DLCheckoutWarning } = handlers;

  return (
    <Drawer
      classes={{ paper: railClasses.paper }}
      anchor="right"
      onClose={handleRailClose}
      open={isCartRailOpen}
      data-testid="cart-rail"
    >
      <CartHeader handleRailClose={handleRailClose} />
      <CartRailBody
        cart={cart}
        isEmptyCart={isEmptyCart}
        isLocalized={isLocalized}
        showInfoAndWarningView={showInfoAndWarningView}
        alertMessageText={alertMessageText}
        showEditCart={showEditCart}
        show7DLInlineWarning={show7DLInlineWarning}
        show7DLCheckoutWarning={show7DLCheckoutWarning}
        cartChangedAlert={cartChangedAlert}
        setShow7DLCheckoutWarning={setShow7DLCheckoutWarning}
      />
    </Drawer>
  );
};

export default CartRail;
