import {
  Modifier,
  Product,
  Nutrition,
  Categories,
  isDip
} from '@pizza-hut-us-development/client-core';
import { UpsellProducts } from '@/graphql/queries/upsell/upsell';
import {
  DisplayableAdditionalOptions,
  DisplayableModifier,
  DisplayableNutritionInfo
} from '@/domain/product/types';
import checkAvailability from '@/graphql/helpers/checkAvailability';
import { OccasionApi } from '@/localization/constants';
import { getDefaultOption, getMatchedSizes, shapeOneClickDefaults } from '../useCCGetProducts/helpers/menuProductsData';
import { extractVariantCodeFromModifiers } from '@/clientCore/helper/extractVariantCodeFromModifiers';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';

const transformNutritionToDisplayableNutritionInfo = (
  nutrition: Nutrition[]
): DisplayableNutritionInfo[] => nutrition.map((nutritionItem) => ({
  name: nutritionItem.name ?? '',
  calories: nutritionItem.calories ?? 0,
  servings: nutritionItem.servings ?? '',
  unit: nutritionItem.unit ?? ''
}));

const transformModifiers = (
  modifiers: Modifier[],
  unhideUpsellPageYum?: boolean
): DisplayableModifier[] => modifiers.map((modifier) => ({
  id: modifier.id,
  name: modifier.name,
  type: modifier.type,
  price: !unhideUpsellPageYum ? modifier.price : 0,
  nutrition: modifier.nutrition
    ? transformNutritionToDisplayableNutritionInfo(modifier.nutrition)
    : [],
  modifiers: modifier.modifiers ? transformModifiers(modifier.modifiers) : [],
  isOutOfStock: modifier.outOfStock,
  sodiumWarning: modifier.sodiumWarning,
  selected: modifier.selected,
  variantCode: modifier.variantCode
}));

const extractAdditionalOptionsFromSizes = (
  modifiers: Modifier[],
  unhideUpsellPageYum?: boolean
): DisplayableAdditionalOptions => modifiers.reduce<{ [key: string]: DisplayableModifier[] }>((acc, current) => {
  if (!current.modifiers) {
    return acc;
  }

  acc[current.name] = transformModifiers(current.modifiers, unhideUpsellPageYum);
  return acc;
}, {});

const getSelectedOptions = (product: Product): DisplayableModifier[] => {
  const modifiers = product.options.flatMap((option) => (option.modifiers ? option.modifiers.filter((modifier) => modifier.selected) : []));
  return transformModifiers(modifiers);
};

// NOTE: Looks like we have to filter out products that are type PIZZA manually as the upsell query
// sometimes returns these. Existing legacy code for this referenced below
// ref: app/domain/product/types.ts
export const transformGetUpsellData = (
  products: Product[],
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean
): UpsellProducts[] => {
  const optimizely = getOrInitializeOptimizely();
  const upsellTransformDipsEnabled = optimizely?.isFeatureEnabled('fr-web-3876-upsell_transform_dips');
  const unhideUpsellPageEnabled = optimizely?.isFeatureEnabled('fr-web-3805-yum-unhide-upsell-page') || false;
  const upsellMeltsVariantCodeEnabled = optimizely?.isFeatureEnabled('fr-web-3961-upsell_melts_variant_code');

  const unhideUpsellPageYum = unhideUpsellPageEnabled && isYumEcomm;

  return products?.filter((product) => product.type !== 'PIZZA').map((rawProduct) => {
    const product = checkAvailability(rawProduct, occasion, storeTimeZone);

    const sizes = product.options
      .map((option) => option.modifiers?.filter((modifier) => modifier.type === 'SIZE') ?? [])
      .reduce((acc, value) => acc.concat(value), []);

    const transformedSizes = sizes ? transformModifiers(sizes, unhideUpsellPageYum) : [];
    const transformedAdditionalOptions = sizes
      ? extractAdditionalOptionsFromSizes(sizes, unhideUpsellPageYum)
      : {};

    const isMelt = product.type === 'MELT';
    const isWings = product.name?.toLowerCase().includes('wing');

    const categoryId = (): Categories | undefined => {
      if (isWings) return Categories.WINGS;
      if (isMelt) return Categories.MELTS;
      return undefined;
    };

    const variantCode = unhideUpsellPageYum
      ? extractVariantCodeFromModifiers(product.selectedOptions.filter((modifier) => modifier !== undefined))
      : extractVariantCodeFromModifiers(product.selectedOptions);

    const getOneClickDefaults = () => {
      // TODO: Consolidate this code when ripping out these flags:
      // - fr-web-3876-upsell_transform_dips
      // - fr-web-3961-upsell_melts_variant_code
      if (isMelt) {
        return upsellMeltsVariantCodeEnabled && isYumEcomm ? shapeOneClickDefaults(product, isYumEcomm) : getSelectedOptions(product);
      }
      if (upsellTransformDipsEnabled && isYumEcomm && isDip(product.id)) {
        return shapeOneClickDefaults(product, isYumEcomm);
      }
      return undefined;
    };

    return {
      // NOTE: Melts used to be type 'PRODUCT' on upsell but they get set to 'MELT' in client core now
      // If they are set to 'PRODUCT' client core strips out the nested crust modifier within size
      // Setting to type 'MELT' ensures they get added to cart whilst shaped like a Product when transforming from AddableCartItem to CartItemInput
      // ref: app/clientCore/temporaryTransformationalHooks/useCCTransformLegacyCartItem/useCCTransformLegacyCartItem.ts
      type: isMelt ? 'MELT' : 'PRODUCT',
      id: product.id,
      available: product.available,
      name: product.name,
      imageUrl: product.imageURL ?? '',
      description: product.description ?? '',
      isOutOfStock: product.outOfStock ?? false,
      sodiumWarning: product.sodiumWarning ?? false,
      displayOrder: product.displayOrder,
      sizes: unhideUpsellPageYum ? getMatchedSizes(product, categoryId()) : transformedSizes,
      additionalOptions: !isMelt ? transformedAdditionalOptions : {},
      preSelectedAdditionalOption: !isMelt ? getDefaultOption(
        product.defaultSelectedOption
      ) : undefined,
      oneClickDefaults: getOneClickDefaults(),
      priority: 0,
      variantCode
    };
  });
};
