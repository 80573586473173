import formattedPrice from '@/common/formattedPrice';

export const CC_CART_ID_COOKIE = '';
export const CC_LOC_TOKEN_COOKIE = '';
export const BACK_TO_CART_LABEL = 'BACK TO CART';
export const WARNING_CONTENT_TITLE_DELIVERY_MINIMUM = 'You have not met the delivery minimum';
export const WARNING_CONTENT_TITLE_PRICE_TC = 'Pricing terms & conditions for this deal';
export const WARNING_CONTENT = (deliveryMinimum: string): string => `The minimum delivery subtotal is ${deliveryMinimum}. Please change your selection to carryout or continue shopping.`;
export const TIP_INITIAL_VALUE = null;
export const EMPTY_CART_TITLE = 'Your cart is hungry';
export const EMPTY_CART_SUBTITLE = 'Fill it with Pizza!';
export const START_YOUR_ORDER = 'Start your order';
export const BACK_TO_CART_BUTTON = 'Back to cart';
export const CLOSE_BUTTON = 'Close';
export const SERVICE_FEE_TITLE = 'Service fee';
export const GOT_IT_BUTTON = 'GOT IT';
export const SERVICE_FEE_INFO_CONTENT = 'The service fee partially offsets the increased cost of operations in the state of California.';
export const ITEMS_REMOVED_WARNING = 'Item(s) removed from cart';
export const APPROACHING_MAX_ORDER_LIMIT_WARNING = (
  maxOrderLimit: string
): string => `You are about to reach the ${maxOrderLimit} maximum order limit for this store`;
export const MAX_ORDER_LIMIT_REACHED_WARNING = 'The maximum order limit for this store has been reached';
export const ORDER_PRICE_CHANGE_WARNING = (
  previousPrice: string,
  updatedPrice: string
): string => `The price of your order has changed from ${previousPrice} to ${updatedPrice}`;
export const MAX_ORDER_LIMIT_EXCEEDED_WARNING = (
  maxOrderLimit: string
): string => `You have exceeded the ${maxOrderLimit} maximum order limit for this store. Please remove items before proceeding to checkout.`;
export const LOADING_ITEM = 'Loading product name';
export const LOADING_MODIFIER = 'Loading product details';
export const GENERIC_PRODUCT_NAME = 'Product name';
export const GENERIC_MODIFIER_NAME = 'Product details';
export const SPECIAL_INSTRUCTIONS = 'Special Instructions';
export const ROKT_ACCOUNT_ID = '2646161524817932499';
export const VALUE_LINEUP_MINIMUM_WARNING = "Please add another Deal Lover's Menu item to meet the 2 item deal minimum.";
export const VALUE_LINEUP_MINIMUM_WARNING_ADD_ITEM = 'Add item';

// @cart/.../AddedCoupons
export const ADDED_COUPON_ID = 'added-coupon-id';
export const REMOVE_COUPON = 'Remove';
export const REMOVE_COUPON_ID = 'remove-coupon-id';
export const COUPON_TITLE = 'Coupon';
export const FORMATTED_DISCOUNT_AMOUNT = (discountedAmount: number): string => `-${formattedPrice(discountedAmount)}`;

// @cart/.../CartItem
export const EDIT_ITEM_TEST_ID = (index: number): string => `cart-rail-edit-item-${index}`;
export const REWARDS_POINTS = (
  points: number
): { textContent: string; ariaLabel: string } => ({
  textContent: `${points} pts`,
  ariaLabel: `${points} points`
});
export const VLU_PREFIX = "Deal Lover's™ Menu - ";

// @cart/.../Coupon
export const COUPON_CODE_ID = 'coupon-code';
export const COUPON_CODE_LABEL = 'Coupon code';
export const COUPON_BUTTON_LABEL = 'Apply';
export const COUPON_BUTTON_ACCORDION_LABEL = 'Have a coupon code?';
export const GUEST_COUPON_ERROR_MESSAGE = 'Sorry, you must sign in to apply this coupon.';
export const YUM_SERIALIZED_CODE_LENGTH = 10;
export const QO_SERIALIZED_CODE_LENGTH = 12;
export const POS_SERIALIZED_CODE_LENGTH = 14;
export const POS_PARSED_SERIALIZED_CODE_LENGTH = 10;
export const YUM_COUPON_ERROR_MESSAGE = 'Coupon not valid at this restaurant';

// @ cart/.../CartCheckout
export const CHECKOUT_SUBMIT_LABEL = 'GO TO CHECKOUT';
export const SERVICE_FEE = 'Service fee';

// @ cart/.../CartRailWarning
export const CENTS_PER_DOLLAR = 100;
export const DELIVERY_MINIMUM_ALERT = 'Delivery Minimum Alert';
export const DELIVERY_MINIMUM = 'Delivery Minimum';
export const CART_ERROR_HANDLING_TITLE = 'Item not updated';
export const CART_ERROR_HANDLING_TEXT = 'Something went wrong with the item you updated. Please try again.';
export const CART_ERROR_HANDLING_CTA = 'OK';

export const CART_PRODUCT_EDIT_LABEL_ANALYTICS = 'Edit Product';
export const CART_PRODUCT_SAVE_LABEL_ANALYTICS = 'Save';
export const CART_PRODUCT_CLOSE_LABEL_ANALYTICS = 'Close';
export const PRICING_TERMS_AND_CONDITIONS = 'Pricing Terms & Conditions';

export const ADD_DEAL_LOVERS_ITEM_CTA = "ADD DEAL LOVER'S ITEM";
export const REMOVE_DEAL_LOVERS_ITEM_CTA = 'REMOVE ITEM AND CHECKOUT';
export const REMOVE_SINGLE_DEAL_LOVERS_ITEM_CTA = 'REMOVE ITEM';
export const DEAL_LOVERS_ENFORCEMENT_WARNING_TITLE = 'Deal minimum not met';
export const DEAL_LOVERS_ENFORCEMENT_WARNING_CONTENT = "Please add another Deal Lover's Menu item to meet the 2 item deal minimum, or continue to checkout and we'll remove the Deal Lover's Menu item from your cart.";
