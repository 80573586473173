import { Category, Product } from '@pizza-hut-us-development/client-core';
import formattedPrice from '@/common/formattedPrice';
import checkAvailability, { AvailabilityItem } from '@/graphql/helpers/checkAvailability';
import { OccasionApi } from '@/localization/constants';
import { DealMenu } from '@/menu/deals/types';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { PARENT_DEAL } from '@/deals/constants';

export const transformDealData = (deal: AvailabilityItem<Product>): MenuRecipe => ({
  allergenDisclaimer: deal.allergenDisclaimer,
  availability: deal.availability,
  available: deal.available,
  availableInOtherOccasion: deal.availableInOtherOccasion,
  displayPrice: deal.price ? formattedPrice(deal.price) : '',
  hidden: deal.hidden,
  id: deal.id,
  imageURL: deal.imageURL,
  legalText: deal.legalText,
  name: deal.name,
  outOfStock: deal.outOfStock,
  price: deal.price,
  priority: deal.displayOrder,
  productDescription: deal.description,
  type: deal.type,
  upcharges: deal.upcharges,
  sodiumWarning: deal.sodiumWarning
} as MenuRecipe);

export const transformGetDealsData = (
  data: Category,
  occasion: OccasionApi,
  storeTimeZone: string
): DealMenu => ({
  deals: data?.products
    .map((product) => {
      const ccAvailProduct = product.type === PARENT_DEAL
        ? { ...product, available: true, availableInOtherOccasion: true }
        : checkAvailability(product, occasion, storeTimeZone);

      return transformDealData(ccAvailProduct);
    })
    .filter((product) => product.available && !product.hidden) as MenuRecipe[]
});
