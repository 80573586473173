import { SelectedRecipe } from './dealTypes';

export const dealWithRecipeAdded = (
  recipes: SelectedRecipe[],
  selectedRecipes: SelectedRecipe[]
): SelectedRecipe[] => {
  const stepIdsFromSelectedRecipes = selectedRecipes.map((recipe) => recipe.stepId);
  const indexFromSelectedRecipes = selectedRecipes.map((recipe) => recipe.index);
  const updatedRecipes = recipes
    ?.filter((recipe, index) => !stepIdsFromSelectedRecipes.includes(recipe.stepId) || !indexFromSelectedRecipes.includes(index)) || [];

  return [
    ...updatedRecipes,
    ...selectedRecipes
  ].sort((a, b) => a && b && (a.index - b.index));
};
