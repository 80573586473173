import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import * as getConfig from 'next/config';
import clientCore from '@pizza-hut-us-development/client-core/package.json';
import LocalizationRail from '../app/localization/localizationRail/LocalizationRail';
import theme from '../app/materialUi/theme';
import Modal from '../app/common/Modal';
import SEO from '../app/SEO';
import UsabilityMode from '../app/common/UsabilityMode';
import ReduxApplication from '../app/ReduxApplication';
import { getApollo } from '@/graphql/apolloClient';
import '../app/shared/pages/contact-us/ChatLiveAgent/LiveAgent.styles.css';
import '../styles/index.css';
import '../app/shared/styles/globals.css';
import '../app/polyfills/focus-visible';
import 'intersection-observer';
import NewRelicSPAAgent from '../app/telemetry/NewRelicSPAAgent';
import { ResponsiveProvider } from '@/context/ResponsiveProvider';
import PageLoading from '../app/common/PageLoading';
import Script from 'next/script';
import useApolloClientClearOnRouteChange from '@/hooks/useApolloClientClearOnRouteChange';
import isFeatureEnabled from '@/toggles/isFeatureEnabled';
import ErrorBoundary from '@/errorBoundary/ErrorBoundary';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import isClientSide from '@/common/isClientSide';
import { getOrInitializeOptimizely } from '../optimizely/optimizely';
import YumLogoutHandler from '@/yumLogoutHandler';
import CCCartRail from '@/clientCore/cart/components/CartRail';
import CartToast from '@/common/CartToast';
import { TemporaryCCProvider } from '@/clientCore/temporaryTransformationalHooks/temporaryCCContext';
import { CustomerManager } from '@/clientCore/components/CustomerManager';
import { CartManager } from '@/clientCore/cart/components/CartManager';

const RailPortalDestination = () => <div id="rail" />;

/* eslint-disable react/prop-types */
export default function MyApp(props) {
  const {
    Component,
    pageProps,
    pageProps: {
      optimizelyData,
      userAgent,
      storeNumber,
      occasion,
      address,
      userAuthentication,
      googleTagManagerFeatureEnabled,
      enableCartV2,
      sessionRememberCart,
      initialApolloState,
      expirationDate,
      issuedDate,
      localizationToken,
      cmsEnvId,
      gqlUrl,
      gqlVersion,
      timestamp,
      localizationTokenSource,
      shouldDisableClientSideResolvers,
      salesExperience,
      pageID,
      yumAuthRefreshData
    }
  } = props;

  const skipGlobalLogic = Component.skipGlobalLogic || false;

  if (skipGlobalLogic) {
    return <Component {...pageProps} />;
  }

  const apolloClient = getApollo(initialApolloState, gqlUrl, shouldDisableClientSideResolvers);

  useApolloClientClearOnRouteChange(apolloClient);

  const isBrowser = isClientSide();
  const { datafile, optimizelyUserId } = optimizelyData;
  const optimizely = getOrInitializeOptimizely({
    datafile,
    isBrowser,
    allowInitialize: true,
    userId: optimizelyUserId
  });

  // Used to trigger a rerender after Optimizely is ready
  const [optimizelyReady, setOptimizelyReady] = useState(false);

  optimizely?.onReady({ timeout: 5_000 })?.then(() => {
    setOptimizelyReady(true);
  });

  useEffect(() => {
    if ((window && 'ph_analytics' in window === false) || optimizelyReady === false) return;

    window.ph_analytics.push({
      event: 'FFE-ClientCore',
      event_category: 'FFE-ClientCore',
      beacon_id: '99.01.02',
      event_action: 'TreatedClientCore',
      event_label: clientCore.version
    });
  }, [optimizelyReady]);

  const user = {
    id: optimizelyUserId,
    attributes: {
      ...optimizely?.user?.attributes,
      storeNumber: storeNumber ?? ''
    }
  };

  const fonts = [
    { font: 'PizzaHutFont.woff2', preload: true },
    { font: 'opensans-regular-webfont.woff2', preload: true },
    { font: 'opensans-bold-webfont.woff2', preload: true },
    { font: 'opensans-semibold-webfont.woff', preload: true },

    { font: 'opensans-regular-webfont.woff', preload: false },
    { font: 'opensans-bold-webfont.woff', preload: false },
    { font: 'PizzaHutFont.woff', preload: false }
  ];

  const { publicRuntimeConfig } = getConfig.default();

  const shouldRenderGoogleTagManagerScript = isFeatureEnabled(
    googleTagManagerFeatureEnabled,
    publicRuntimeConfig?.ENABLE_GOOGLE_TAG_MANAGER,
    salesExperience,
    true
  );

  const cartManagerFixEnabled = optimizely?.isFeatureEnabled('fr-web-3804-cart_manager_fix');

  return (
    <>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.ph_analytics = window.ph_analytics || [];
          window.ph_analytics.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        `}
      </Script>
      {shouldRenderGoogleTagManagerScript && (
        <Script
          strategy="afterInteractive"
          src="https://www.googletagmanager.com/gtm.js?id=GTM-MXNZFSM&l=ph_analytics"
        />
      )}
      <Head>
        <script src="https://cdn.optimizely.com/js/20923401607.js" />
        {fonts.map(({ font, preload }) => (
          <link key={font} rel={`${preload ? 'preload' : ''}`} href={`/fonts/${font}`} as="font" crossOrigin="" />
        ))}
        <link rel="stylesheet" href="/fonts/fonts.css" />
        <link rel="icon" href="/images/favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=1" />
        <NewRelicSPAAgent />
        <script
          async
          defer
          src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyAQvCapPzUi-uY2BMMtaY54WSVfSLHH_6o"
        />
        <script
          async
          crossOrigin="anonymous"
          id="rokt-launcher"
          importance="high"
          src="https://apps.rokt.com/wsdk/integrations/launcher.js"
          type="module"
        />
        <script src="https://www.google.com/recaptcha/api.js" async defer />
      </Head>
      <ErrorBoundary>
        <OptimizelyProvider optimizely={optimizely} user={user} isServerSide={!isBrowser}>
          <ApolloProvider client={apolloClient}>
            <ReduxApplication
              cmsEnvId={cmsEnvId}
              gqlVersion={gqlVersion}
              storeNumber={storeNumber}
              occasion={occasion}
              localizedTokenAddress={address}
              userAuthentication={userAuthentication}
              enableCartV2Features={enableCartV2}
              sessionRememberCart={sessionRememberCart}
              expirationDate={expirationDate}
              issuedDate={issuedDate}
              localizationToken={localizationToken}
              timestamp={timestamp}
              localizationTokenSource={localizationTokenSource}
              salesExperience={salesExperience}
              yumAuthRefreshData={yumAuthRefreshData}
            >
              <SEO pageID={pageID} />
              <div id="6ec5b70efoasfpoijos" />
              <ThemeProvider theme={theme({ htmlFontSize: 10 })}>
                <ResponsiveProvider value={{ userAgent }}>
                  <CssBaseline />
                  <PageLoading>
                    <TemporaryCCProvider>
                      {/* Services */}
                      {cartManagerFixEnabled ? <CartManager /> : null}
                      <CustomerManager />
                      {/* Main Children */}
                      <Component {...pageProps} />
                    </TemporaryCCProvider>
                  </PageLoading>
                  <LocalizationRail />
                  <RailPortalDestination />
                  <YumLogoutHandler />
                  <Modal />
                  <CCCartRail />
                  <CartToast />
                  <UsabilityMode />
                </ResponsiveProvider>
              </ThemeProvider>
            </ReduxApplication>
          </ApolloProvider>
        </OptimizelyProvider>
      </ErrorBoundary>
    </>
  );
}
